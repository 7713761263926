<template>
  <div class="text-black flex flex-col h-screen">
    <Navbar />
    <div id="content" class="mx-10 flex-grow">
      <div class="profile-details md:pt-0">
        <div v-if="profile.flags.is_candidate">
          <div
            class="md:justify-self-end w-full text-center md:w-auto md:items-center pt-18"
          >
            <img
              :src="
                require('@/assets/images/profile/candidate_incomplete_profile.svg')
              "
              class="reports-content relative left-1/2 transform -translate-x-1/2"
            />
            <p class="text-xl md:text-3xl font-semibold p-text-1 pt-4">
              This feature is for employees only,
            </p>
            <p class="text-xl md:text-3xl font-semibold md:pt-2 pb-4">
              It will be activated for you on {{ profile.date_of_joining }}.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Common/Navbar.vue";
import Footer from "@/components/Common/Footer.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: { Navbar, Footer },
  created() {
    this.$store.dispatch("getProfile", {});
  },
  mounted() {},
  computed: {
    ...mapGetters(["profile"]),
  },
};
</script>

<style scoped></style>
